import React from "react";
import styles from "../../styles/footer.module.scss";
import { SocialsMobileComponent } from "./SocialsMobile.component";
import ModalComponent from "./modal-terms.component";

export const FooterComponent = () => {
  const [visibleModal, setVisibleModal] = React.useState<boolean>(false);
  const [modalType, setModalType] = React.useState<string>("");

  const handleOpenModal = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    type: string
  ) => {
    e.preventDefault();
    setModalType(type);
    setVisibleModal(true);
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div>
          <div>
            <p className={styles.title}>Contact Us:</p>
          </div>
          <p>World Headquarters </p>
          <p>Oficentro Plaza Roble</p>
          <p>Edificio Los Balcones b</p>
          <p>Primer nivel</p>
          <p>Guachipelin, Escazu San Jose</p>
          <div className={styles.country}>
            <span>Costa Rica</span>{" "}
            <img src="/assets/img/costarica_flag.svg" width={20} />
          </div>
        </div>
        <div style={{ textAlign: "right" }}>
          <a
            href="#"
            style={{
              margin: "0px",
              lineHeight: "inherit",
              fontSize: "14px",
              display: "inline-block",
              color: "#fff",
              paddingRight: "10px",
            }}
            onClick={(e) => handleOpenModal(e, "privacy")}
          >
            Privacy Policy
          </a>
          <a
            href="#"
            style={{
              margin: "0px",
              lineHeight: "inherit",
              fontSize: "14px",
              display: "inline-block",
              color: "#fff",
            }}
            onClick={(e) => handleOpenModal(e, "sms")}
          >
            SMS Terms
          </a>
        </div>
      </div>
      <SocialsMobileComponent />

      <ModalComponent
        visible={visibleModal}
        closeModal={() => setVisibleModal(false)}
        type={modalType}
      />
    </footer>
  );
};
