import React from "react";

import styles from "../../styles/casino-container.module.scss";

interface Props {
  clickOnBanner: () => void;
}

export const CasinoContainerComponent: React.FC<Props> = ({ clickOnBanner }) => {

  return (
    <div className={styles.container}>
      <div className={styles.casino} onClick={clickOnBanner}>
        <a href="#">
          <img src="/assets/img/casino/casino.png" alt="" />
        </a>
      </div>
      <div className={styles.games}>
        <div className={styles['game-card']} onClick={clickOnBanner}>
          <div className={styles['game-card__header']} style={{backgroundImage: 'url(/assets/img/casino/blackjack.png)'}}>
            <h3>Blackjack</h3>
          </div>
          <div className={styles['game-card__footer']}>
            <span>Play now</span>
            <img src="/assets/img/casino/arrow-right.png" alt="" />
          </div>
        </div>
        <div className={styles['game-card']} onClick={clickOnBanner}>
          <div className={styles['game-card__header']} style={{backgroundImage: 'url(/assets/img/casino/live-dealer.png)'}}>
            <h3>Live Dealer</h3>
          </div>
          <div className={styles['game-card__footer']}>
            <span>Play now</span>
            <img src="/assets/img/casino/arrow-right.png" alt="" />
          </div>
        </div>
        <div className={styles['game-card']} onClick={clickOnBanner}>
          <div className={styles['game-card__header']} style={{backgroundImage: 'url(/assets/img/casino/slots.png)'}}>
            <h3>Slots</h3>
          </div>
          <div className={styles['game-card__footer']}>
            <span>Play now</span>
            <img src="/assets/img/casino/arrow-right.png" alt="" />
          </div>
        </div>
        <div className={styles['game-card']} onClick={clickOnBanner}>
          <div className={styles['game-card__header']} style={{backgroundImage: 'url(/assets/img/casino/ruleta.png)'}}>
            <h3>And More...</h3>
          </div>
          <div className={styles['game-card__footer']}>
            <span>Play now</span>
            <img src="/assets/img/casino/arrow-right.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}