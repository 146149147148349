import React from "react";
import "./styles.scss";
import { dataMockStepTwo } from "./datas";

const CryptoStepTwo = () => {
  return (
    <div className="crypto-content_steps two">
      <div className="crypto-step max-container">
        <div className="crypto-step_header">
          <span>2</span>
          <h3>Buy your preferred cryptocurrency</h3>
        </div>
        <div className="crypto-step_content">
          <p>We recommend Bitcoin for beginners</p>
        </div>
      </div>
      <div className="crypto-step_cards">
        <ul className="cards-two">
          {dataMockStepTwo.map((data) => (
            <li className="list-item" key={data.id}>
              <img width={40} height={40} src={data.img} alt={data.name} />
              <h4>{data.name}</h4>
              <p>{data.description}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CryptoStepTwo;
