import React from "react";
import { ButtonComponent } from "../commons/Button.component";
import styles from "../../styles/slider.module.scss";

interface Props {
  index: number;
  openModal: () => void;
}

export const StaticSlideComponent: React.FC<Props> = ({ index, openModal }) => {
  return (
    <div style={{ width: "100%" }}>
      <div className={`${styles.slide} ${styles["slide--static"]}`}>
        <div className={styles.content}>
          <div className={styles.bg2}>
            <img
              src={`/assets/img/slides/bg-slide-${index}.svg`}
              alt={`bg-slide-${index}`}
            />
          </div>
          <div className={`${styles.bg4} ${styles[`bg4-${index}`]}`}>
            <div className={styles.btn}>
              <ButtonComponent onClick={openModal} style="banner">
                JOIN NOW
              </ButtonComponent>
            </div>
          </div>
          <div className={styles.bg5}>
            <img
              src={`/assets/img/slides/bg-slide-${index}-desk.svg`}
              alt={`bg-slide-${index}-desk`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
