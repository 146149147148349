import React from "react";

import styles from "../../styles/pagination.module.scss";
import { paginate } from '../../utils/pagination';

interface Props {
  data: unknown[];
  pages: number[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export const PaginationComponent: React.FC<Props> = (
  {
    data,
    pages,
    currentPage,
    setCurrentPage,
  }
) => {
  return (
    <div className={styles.pagination}>
      {pages.map((page, index) => (
        <div
          key={index}
          className={`${styles.page} ${index + 1 === currentPage ? styles.active : ""
            }`}
          onClick={() => {
            setCurrentPage(index + 1);
          }}
        >
          {index + 1}
        </div>
      ))}
    </div>
  )
}