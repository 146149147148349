import React from "react";
import styles from "../../styles/button.module.scss";

interface Props {
  style: 'primary' | 'secondary' | 'banner';
  onClick?: () => void;
  children?: React.ReactNode;
}

export const ButtonComponent: React.FC<Props> = ({ style, onClick, children }) => {
  return (
    <button className={`${styles.button} ${styles[`button--${style}`]}`} onClick={onClick}>
      {children}
    </button>
  )
};
