import React from "react";

import styles from "../../styles/dynamic-content.module.scss";
import { MarketsComponent, Game } from "../dynamic-content/MarketsComponent";
import { VideosComponent, Video } from "../dynamic-content/VideosComponent";
import { Faq, FaqComponent } from "../dynamic-content/FaqComponent";
import { News, NewsComponent } from "../dynamic-content/NewsComponent";
import { SideBarContainer } from "./SideBarContainer.component";
// import faq json
import faqJson from "../../utils/faq.json";
import { ReviewsComponent } from "./Reviews.component";

const OPTIONS = [
  {
    title: "reviews",
    data: [],
  },
  {
    title: "faqs",
    data: faqJson,
  },
  {
    title: "videos",
    data: [],
  },
  {
    title: "recent news",
  },
];

export const DynamicContentComponent: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [news, setNews] = React.useState<News[]>([]);
  const [games, setGames] = React.useState<Game[]>([]);
  const [videos, setVideos] = React.useState<Video[]>([]);

  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return <ReviewsComponent />;
      case 1:
        return <FaqComponent data={OPTIONS[1].data as Faq[]} />;
      case 2:
        return <VideosComponent data={videos} onLoaded={(v) => setVideos(v)} />;
      case 3:
        return <NewsComponent data={news} onLoaded={(n) => setNews(n)} />;
      default:
        return <div>Markets</div>;
    }
  };

  const getTitle = (index: number) => {
    return OPTIONS[index].title;
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.menu}>
          {OPTIONS.map((option, index) => (
            <div
              className={`${styles["menu-item"]} ${
                index === activeTab ? styles["menu-item--active"] : ""
              }`}
              key={index}
              onClick={() => setActiveTab(index)}
            >
              <span>{option.title}</span>
            </div>
          ))}
        </div>
        <div className={`${styles.divider}`}></div>
        <div className={styles["dynamic-container"]}>
          <div
            className={[
              styles.title,
              getTitle(activeTab) === "faqs" ? styles.uppercase : "",
            ].join(" ")}
          >
            <h2>{getTitle(activeTab)}</h2>
          </div>
          <div className={`${styles.divider} ${styles["divider--line"]}`}></div>
          {renderContent()}
        </div>
      </div>
      <div className={styles["side-bar"]}>
        <SideBarContainer />
      </div>
    </div>
  );
};
