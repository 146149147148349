import React from 'react'
import { paginate, getPages } from './pagination';

export const usePagination = (data: unknown[], limit: number) => {
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [paginatedData, setPaginatedData] = React.useState<unknown[]>(paginate(data, currentPage, limit));
  const [totalPages, setTotalPages] = React.useState<number[]>(getPages(data.length, limit));


  React.useEffect(() => {
    setTotalPages(getPages(data.length, limit));
    setPaginatedData(paginate(data, currentPage, limit));
  }, [data, currentPage, limit])

  return [
    paginatedData,
    currentPage,
    totalPages,
    setCurrentPage,
  ]
}
