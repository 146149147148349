import React from "react";

import styles from '../../styles/markets.module.scss';
import { formatDate } from '../../utils/date';
import { getGames } from '../../utils/api';
import { usePagination } from '../../utils/pagination.hook';
import { PaginationComponent } from '../commons/Pagination.component';

interface Props {
  data: Game[]
  onLoaded: (data: Game[]) => void;
}

export interface Game {
  date: string;
  denied: string;
  team_1: string;
  team_1_logo: string;
  team_2: string;
  team_2_logo: string;
}

const IMAGE_BASE_URL = 'https:////sp.sportsaction77.com/tooltip/';

export const MarketsComponent: React.FC<Props> = ({ data, onLoaded }) => {

  const [
    games,
    currentPage,
    totalPages,
    setCurrentPage
  ] = usePagination(data, 3);

  React.useEffect(() => {
    const getData = async () => {
      const res = await getGames();
      if (res.LIST?.ITEMS) {
        onLoaded(res.LIST.ITEMS as Game[]);
      }
    }
    if (!data.length) {
      getData();
    }
  }, [data, onLoaded]);

  return (
    <>
      <div className={[
        styles.markets,
        (games as Game[]).length > 3 ? styles['markets--scroll'] : ''
      ].join(' ')}>
        {(games as Game[]).map((game, index) => (
          <div className={[styles.card, index + 1 === (games as Game[]).length? styles.last : ''].join(' ')} key={index}>
            <div className={styles.content}>
              <div className={styles.team}>
                <div className={styles['team__logo']}>
                  <img src={IMAGE_BASE_URL + game.team_1_logo} alt={game.team_1} />
                </div>
                <span>{game.team_1}</span>
              </div>
              <div className={styles.versus}>
                <span>VS</span>
              </div>
              <div className={styles.team}>
                <div className={styles['team__logo']}>
                  <img src={IMAGE_BASE_URL + game.team_2_logo} alt={game.team_2} />
                </div>
                <span>{game.team_2}</span>
              </div>
            </div>
            <div className={styles.footer}>
              <span>{formatDate(new Date(game.date))}</span>
            </div>
          </div>
        ))}
        {(totalPages as number[]).length > 1 && (
          <div className={styles.pagination}>
            <PaginationComponent
              data={games as unknown[]}
              pages={totalPages as number[]}
              currentPage={currentPage as number}
              setCurrentPage={setCurrentPage as (page: number) => void}
            />
          </div>
        )}
      </div>
      </>
  )
}