
// get number of pages as array of numbers
export const getPages = (total: number, limit: number) => {
  const pages = Math.ceil(total / limit);
  return Array.from({ length: pages }, (_, i) => i + 1);
}

// get products for a specific page
export const paginate = (products: any[], page: number, limit: number) => {
  const startIndex = (page - 1) * limit;
  const endIndex = page * limit;
  return products.slice(startIndex, endIndex);
}