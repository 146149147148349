import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface Props {
  type: string;
  visible: boolean;
  closeModal: () => void;
}

const ModalComponent: React.FC<Props> = ({ visible, closeModal, type }) => {
  return (
    <div className={`modal ${visible ? "modal--active" : ""}`}>
      <div className="overlay">
        <div
          className="modal-card"
          style={{ height: "350px", overflow: "scroll", position: "relative" }}
        >
          <div
            style={{
              position: "absolute",
              top: "15px",
              right: "15px",
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          {type === "sms" ? (
            <>
              <h3>SMS TERMS & CONDITIONS</h3>
              <p>
                When opted-in, you will receive text messages (SMS/MMS) to your
                mobile number. These kinds of messages may include offers,
                coupons, or other information Such as new events, updates,
                transactional and marketing messeges.
              </p>
              <p>
                You can opt-out of this service at any time. Just text “STOP” to
                the phone number. After you text “STOP” to us, we may send you
                an SMS reply to confirm that you have been unsubscribed. After
                this, you will no longer receive SMS messages from us. If you
                want to join again, just sign up as you did the first time, or
                text “START” or “JOIN” and we will start sending SMS messages to
                you again.
              </p>
              <p>
                If you are experiencing any issues, you can reply with the
                keyword “HELP.” Or, you can get help directly from us{" "}
                <a href="mailto:wagerattackops@gmail.com">
                  wagerattackops@gmail.com
                </a>{" "}
                or{" "}
                <a href="https://wagerattack/contact-us">
                  www.wagerattack/contact-us
                </a>
                .
              </p>
              <p>
                Carriers, such as AT&T, are not liable for delayed or
                undelivered messages.
              </p>
              <p>
                Message and data rates may apply for any messages sent to you
                from us and to us from you. You should expect to receive 2-4
                messages per month from us.
              </p>
              <p>
                If you have any questions about your text plan or data plan,
                please contact your wireless provider.
              </p>
              <p>
                If you have any questions regarding privacy, please read our
                privacy policy..
              </p>
            </>
          ) : (
            <>
              <h3>WagerAttack Privacy Policy</h3>
              <strong>Our Commitment to Privacy</strong>
              <p>
                <strong>WagerAttack</strong> ("we," "our," or "us") is committed
                to protecting the privacy and security of your personal
                information. This Privacy Policy outlines the types of
                information we collect from you, how we use and protect that
                information, and your rights regarding your personal data. By
                using our website and services, you consent to the practices
                described in this policy.
              </p>
              <strong>Information We Collect</strong>
              <p>
                When you visit our website or use our services, we may collect
                certain types of information:
              </p>
              <p>
                Personal Information: We may collect personal information, such
                as your name, email address, phone number, and other contact
                details, only when you voluntarily provide it to us through
                forms or other means on our website.
              </p>
              <strong>How We Use Your Information</strong>
              <p>
                We use the information we collect for the following purposes:
              </p>
              <p>
                To facilitate property transactions: The property-related
                information we collect is used to complete the buying process,
                including property assessments and necessary documentation.
              </p>
              <p>
                To improve our services: We may use the information to enhance
                and customize our services, website content, and user
                experience.
              </p>
              <p>
                To communicate with you: We may use your contact information to
                respond to your inquiries, provide updates on property
                purchases, and share relevant information.
              </p>
              <strong>Opt-In for Marketing Text Messages</strong>

              <p>
                By submitting the contact form and signing up for texts, you
                consent to receive marketing text messages from{" "}
                <strong>WagerAttack</strong> at the number provided. Consent is
                not a condition of purchase. Message and data rates may apply.
                You can unsubscribe at any time by replying STOP to the received
                SMS (texts) or clicking the unsubscribe link (where available)
                in the marketing text messages.
              </p>
              <strong>Opt-In Information and Third-Party Sharing</strong>
              <p>
                We respect your privacy choices, especially concerning the
                sharing of your personal information. Any information you
                provide through opt-in consent will not be shared with third
                parties for marketing or promotional purposes.
              </p>
              <strong>Data Security</strong>
              <p>
                We take reasonable measures to protect your personal information
                from unauthorized access, alteration, disclosure, or
                destruction. However, no method of transmission over the
                Internet or electronic storage is entirely secure, and we cannot
                guarantee absolute security.
              </p>
              <strong>Your Rights</strong>
              <p>
                You have the right to access, update, correct, and delete your
                personal information we hold. If you wish to exercise any of
                these rights, please contact us at{" "}
                <a href="mailto:wagerattackops@gmail.com">
                  wagerattackops@gmail.com
                </a>
              </p>
              <strong>Changes to this Privacy Policy</strong>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or for other operational, legal, or
                regulatory reasons. The updated policy will be posted on our
                website, and the effective date will be revised accordingly.
              </p>
              <strong>Contact Us</strong>
              <p>
                If you have any questions, concerns, or requests related to this
                Privacy Policy, please contact us at{" "}
                <a href="mailto:wagerattackops@gmail.com">
                  wagerattackops@gmail.com
                </a>
                .
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
