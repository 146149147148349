import React from "react";

import styles from "../../styles/reviews.module.scss";

import reviewsData from '../../utils/reviews.json'
import { paginate, getPages } from '../../utils/pagination';
import { PaginationComponent } from "../commons/Pagination.component";
import { usePagination } from '../../utils/pagination.hook';

interface Review {
  comment: string;
  name: string;
  stars: number;
}
export const ReviewsComponent: React.FC = () => {
  const [
    reviews,
    currentPage,
    totalPages,
    setCurrentPage,
  ] = usePagination(reviewsData, 5);

  const [reviewInfo, setReviewInfo] = React.useState({
    total: 0,
    average: 0,
    roundAverage: 0,
  });

  React.useEffect(() => {
    const totalReviews = reviewsData.length;
    const totalScore = reviewsData.reduce((acc, review) => acc + review.stars, 0);
    const average = totalScore / totalReviews;
    const cleanAverage = Math.round(average * 10) / 10;
    const roundedAverage = Math.ceil(average);
    setReviewInfo({
      total: totalScore,
      average: cleanAverage,
      roundAverage: roundedAverage,
    })

  }, []);
  return (
    <div className={styles.reviews}>
      {(reviews as Review[]).map((r, index) => (
        <div key={'review_' + index}>
          <div className={styles.review}>
            <div className={styles.comment}>
              <p>{r.comment}</p>
            </div>
            <div className={styles.name}>
              <p>{r.name}</p>
            </div>
          </div>
          <div className={styles.divider}></div>
        </div>
      ))}
      {(totalPages as number[]).length > 1 && (
        <div className={styles.pagination}>
          <PaginationComponent
            data={reviewsData}
            pages={totalPages as number[]}
            currentPage={currentPage as number}
            setCurrentPage={setCurrentPage as (page: number) => void}
            />
        </div>
      )}
    </div>
  )
}
