import "./styles/_settings.scss";
import { allowedPaymentMethods, benefitsList } from "../../constants";

// COMPONENTS
import { RegisterForm } from "./Components/Register";
import { SocialsMobileComponent } from "../../components/ui/SocialsMobile.component";

// ASSETS
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Landing = () => {
  return (
    <div data-landing className="landing">
      {/** HEADER */}
      <header className="lg-header">
        <div className="lg-container">
          <div className="payments-containers">
            <div className="icons">
              {allowedPaymentMethods.map((method, index) => (
                <div key={index} className="icon">
                  <img
                    src={`/assets/img/icons/${method.icon}`}
                    alt={method.name}
                    className="icon-img"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </header>
      {/** END-HEADER */}
      {/** HERO */}
      <div className="lg-hero">
        <div className="lg-container">
          <hgroup>
            <div className="hg-flex">
              <h3>WAGER</h3>
              <div>
                <span>ATTACK</span>
                <span>.com</span>
              </div>
            </div>
            <div className="hg-flex">
              <h1>WIN MORE,</h1>
              <h2>LOSE LESS!</h2>
            </div>
          </hgroup>
        </div>
      </div>
      {/** END-HERO */}
      {/** HERO-MID */}
      <div className="lg-hero-mid">
        <div className="lg-container">
          <h3>Enjoy the benefits of:</h3>
          <div className="lg-hero-mid_flex">
            {benefitsList.map((item, index) => (
              <div key={index} className="lg-hero-mid_card">
                <img
                  src={`/assets/img/landing/${item.icon}`}
                  alt={`landing-${item.name}`}
                />
                <div dangerouslySetInnerHTML={{ __html: item.name }} />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/** END-HERO-MID */}
      {/** MID */}
      <div className="lg-mid-container">
        <div className="lg-container">
          <div className="lg-mid-wrap">
            <div className="lg-mid-including">
              <h3>With more ways to win including: </h3>
              <ul>
                <li>Live Betting</li>
                <li>Prop Builder</li>
                <li>International Sports</li>
                <li>Table Games</li>
                <li>Live Dealers</li>
              </ul>
            </div>
            <div className="lg-mid-crypto">
              <img src="/assets/img/icons/icon-crypto.png" alt="crypto" />
              <h3>Switch to a Crypto Only Account!</h3>
              <div>
                <FontAwesomeIcon color="#FFCD00" icon={faComment} />
                <span>Contact our Live Support</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** END-MID */}
      <div className="lg-mid-level-container">
        <div className="lg-container"></div>
      </div>
      {/** FOOTER-MID */}
      <div className="lg-footer-mid-container">
        <div className="lg-container">
          <div className="cards-perks-wraps">
            <h3>Perks Include:</h3>
            <div className="cards-perks-wrap">
              <div className="card-perks">
                <h3>10%</h3>
                <div>
                  <p>Cash Bonus on Every Deposit</p>
                  <p>(1x Rollover)</p>
                </div>
              </div>
              <div className="card-perks">
                <div>
                  <p>Free Play Bonuses up to</p>
                  <p>(Every Eligible Deposit)</p>
                </div>
                <h3>200%</h3>
              </div>
              <div className="card-perks">
                <div>
                  <h4>Bitcoin payouts</h4>
                  <p>
                    received a within hours after approval{" "}
                    <span>(No fees)</span>
                  </p>
                </div>
              </div>
              <div className="card-perks">
                <p>
                  Unlimited Withdrawal Requests{" "}
                  <span>(up to $5000 per 7 days)</span>
                </p>
              </div>
            </div>
            <h4>Must only deposit Crypto to be eligible for perks</h4>
          </div>
        </div>
      </div>
      {/** END-FOOTER-MID */}

      {/** FORM */}
      <RegisterForm />
      <div className="lg-form-footer">
        <div className="lg-form-footer_header">
          <div>
            <h3>WagerAttack</h3>
            <h3>is international,</h3>
            <p>
              means you can play from anywhere. We encourage crypto too with
              same day withdrawals and bonuses upon deposit!
            </p>
          </div>
        </div>
        <div className="lg-form-footer_sign">
          <p>Sign-up and make your first deposit!</p>
        </div>
      </div>
      {/** END-FORM */}
      <footer className="lg-footer">
        <div className="lg-container">
          <div className="footer-description">
            <p className="footer-about">
              Contact Us:{" "}
              <a href="mailto:wagerattackops@gmail.com">
                wagerattackops@gmail.com
              </a>
            </p>
            <p>
              World Headquarters, Oficentro Plaza Roble, Edificio Los Balcones
              b, Primer nivel, Guachipelin, Escazu San Jose, Costa Rica
            </p>
          </div>
          <SocialsMobileComponent />
        </div>
      </footer>
    </div>
  );
};

export default Landing;
