import React from "react";

import styles from '../../styles/news.module.scss';
import { NewComponent } from './NewComponent';
import { formatDate } from '../../utils/date';
import { getNews } from '../../utils/api';

interface Props {
  data: News[];
  onLoaded: (data: News[]) => void;
}

export interface News {
  date: string;
  title: string;
  image: string;
  description: string;
  denied: string;
}

const BASE_NEW_IMAGE_URL = 'https://sp.sportsaction77.com/tooltip/file/new/';

export const NewsComponent: React.FC<Props> = (
  { data, onLoaded }
) => {
  const [active, setActive] = React.useState<number>(0);
  const ref = React.useRef <HTMLDivElement>(null);

  React.useEffect(() => {
    const getData = async () => {
      const res = await getNews();
      if (res.LIST?.ITEMS) {
        onLoaded(res.LIST.ITEMS as News[]);
      }
    }
    if (!data.length) {
      getData();
    }
  }, [data, onLoaded]);

  const handlerActiveNew = (index: number) => {
    setActive(index);
    if (window.innerWidth < 768) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }
  // prevent default behavior of the browser
  const clickResult = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
  }

  return (
    <div className={styles.news}>
      {data.length > 0 && (
        <div ref={ref} className={styles['news-selected-box']}>
          <NewComponent {...data[active]} />
        </div>
      )}
      <div className={styles.list}>
        {data.map((news, index) => (
          <div className={`${styles['news-card']} ${index === active ? styles['news-card--active'] : ''}`} key={index} onClick={() => handlerActiveNew(index)}>
            <div className={styles['image-box']}>
              <img src={`${BASE_NEW_IMAGE_URL}${news.image}`} alt={news.title} />
            </div>
            <div className={styles['content-box']}>
              <h4>{news.title}</h4>
              <p>{formatDate(new Date(news.date), true)}</p>
            </div>
            <div className={styles['news-card__result']} onClick={clickResult}>
              <NewComponent {...news} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}