export const formatDate = (date: Date, short = false) => {
  const month = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();
  const hour= date.getHours();
  let min: number | string = date.getMinutes();

  if (min < 10) {
    min = '0' + min;
  }

  // get month name
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const monthName = monthNames[month];

  // get day with suffix
  const daySuffix = (day: number) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  if (short) {
    return `${monthName} ${day}, ${year}`;
  }
  

  return `${monthName} ${day}${daySuffix(day)}, ${year} - ${hour}:${min}`;
}

export const getCountdownFormat = (date: Date) => {
  const now = new Date();
  const timeLeft = date.getTime() - now.getTime();
  let days: string | number = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  let hours: string | number = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
  let minutes: string | number = Math.floor((timeLeft / 1000 / 60) % 60);
  let seconds: string | number = Math.floor((timeLeft / 1000) % 60);

  if (days < 0) {
    days = 0;
  }
  if (hours < 0) {
    hours = 0;
  }
  if (minutes < 0) {
    minutes = 0;
  }
  if (seconds < 0) {
    seconds = 0;
  }

  if (days < 10) {
    days = '0' + days;
  }
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return {
    days,
    hours,
    minutes,
    seconds
  };
};