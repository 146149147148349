import React from "react";

import styles from '../../styles/sidebar.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";
import { getCountdownFormat, formatDate } from '../../utils/date';
import { getCountDown, getMessage } from '../../utils/api';
import { ReviewsComponent } from "./Reviews.component";
import { MarketsComponent, Game } from '../dynamic-content/MarketsComponent';


export const SideBarContainer = () => {
  const [date, setDate] = React.useState(new Date());
  const [count, setCount] = React.useState(getCountdownFormat(date));
  const [eventDate, setEventDate] = React.useState(formatDate(date));
  const [zone, setZone] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [markets, setMarkets] = React.useState<Game[]>([]);

  React.useEffect(() => {
    const timer = setTimeout(
      () => {
        setCount(getCountdownFormat(date))
      },
    1000);
    return () => clearTimeout(timer)
  }, [count, date]);

  React.useEffect(() => {
    const getData = async () => {
      const res = await getCountDown();
      const msg = await getMessage();
      
      if (res.INFO?.GET) {
        setEventDate(formatDate(new Date(res.INFO.GET.newdate)));
        setZone(res.INFO.GET.zone);
        setDescription(res.INFO.GET.description);
        setDate(new Date(res.INFO.GET.newdate));
        setTitle(res.INFO.GET.title);
      }

      if (res.INFO?.GET) {
        setMessage(msg.INFO.GET.description);
      }
    }

    getData();
  }, [])

  return (
    <div className={styles.sidebar}>
      <div className={styles['sidebar-item']}>
        <div className={styles['sidebar-item__title']}>
          <h2>{title}</h2>
        </div>
        <div className={`${styles['sidebar-item__content']} ${styles.countdown}`}>
          <div className={styles.time}>
            <div>
              <span>{count.days}</span>
              <span className={styles.label}>days</span>
            </div>
            <div>
              <span>{count.hours}</span>
              <span className={styles.label}>hr</span>
            </div>
            <div>
              <span>{count.minutes}</span>
              <span className={styles.label}>min</span>
            </div>
            <div>
              <span>{count.seconds}</span>
              <span className={styles.label}>sec</span>
            </div>
          </div>
          <div className={styles['date-info']}>
            <div className={styles.item}>
              <FontAwesomeIcon 
                icon={faCalendar} 
                style={{ color: '#E43F40', fontSize: '10px' }} />
              <span>{eventDate}</span>
            </div>
            <div className={styles.item}>
              <FontAwesomeIcon icon={faClock} style={{ color: '#E43F40', fontSize: '10px' }} />
              <span>{zone}</span>
            </div>
          </div>
          <div className={styles['description']}>
            <p>{description}</p>
          </div>
        </div>
      </div>
      <div className={styles['sidebar-item']}>
        <div className={styles['sidebar-item__title']}>
          <h2>IMPORTANT MESSAGES</h2>
        </div>
        <div className={styles['sidebar-item__content']}>
          <p>{message}</p>
        </div>
      </div>

      <div className={styles['sidebar-item']}>
        <div className={`${styles['sidebar-item__title']} ${styles['sidebar-item__title--dark']}`}>
          <h2>MARKETS</h2>
        </div>
        <div className={`${styles['sidebar-item__content']} ${styles['sidebar-item__content--full']}`}>
          <MarketsComponent data={markets} onLoaded={(markets) => setMarkets(markets as Game[])} />
        </div>
      </div>
    </div>
  )
}