import React from "react";

import styles from '../../styles/faq.module.scss';
import { usePagination } from '../../utils/pagination.hook';
import { PaginationComponent } from '../commons/Pagination.component';

interface Props {
  data: Faq[],
}
export interface Faq {
  title: string;
  description: string[];
}

export const FaqComponent: React.FC<Props> = ({data}) => {
  const [active, setActive] = React.useState<number>(0);
  const [
    faqs,
    currentPage,
    totalPages,
    setCurrentPage
  ] = usePagination(data, 10);

  return (
    <div className={styles.faq}>
      {(faqs as Faq[]).map((faq, index) => (
        <div className={`${styles['faq-card']} ${index === active ? styles['faq-card--active'] : ''}`} key={index}>
          <div className={styles['header']} onClick={() => setActive(index)}>
            <span>{faq.title}</span>
            <div>
              <div className={styles['header__arrow']}></div>
            </div>
          </div>
          <div className={styles.content}>
            {faq.description.map((desc, index) => (
              <p key={`desc-${index}`}>{desc}</p>
            ))}
          </div>
          {index < data.length - 1 && <div className={styles['divider']}></div>}
        </div>
      ))}
      {(totalPages as number[]).length > 1 && (
        <div className={styles.pagination}>
          <PaginationComponent
            data={faqs as unknown[]}
            pages={totalPages as number[]}
            currentPage={currentPage as number}
            setCurrentPage={setCurrentPage as (page: number) => void}
          />
        </div>
      )}
    </div>
  )
}