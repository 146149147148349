import React from "react";

import styles from '../../styles/news.module.scss';

import { News } from './NewsComponent';
import { formatDate } from '../../utils/date';

const BASE_NEW_IMAGE_URL = 'https://sp.sportsaction77.com/tooltip/file/new/';

export const NewComponent: React.FC<News> = ({
  title,
  image,
  description,
  date,
}) => {
  return (
    <div className={styles['news-selected']}>
      <h3>{title}</h3>
      <img src={`${BASE_NEW_IMAGE_URL}${image}`} alt={title} />
      <p>{description}</p>
      <p className={styles.date}>{formatDate(new Date(date), true)}</p>
    </div>
  )
}