import React from "react";

import styles from "../../styles/socials.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faTiktok,
  faSnapchat,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

export const SocialsMobileComponent: React.FC = () => {
  return (
    <div className={styles["socials-mobile"]}>
      <a
        href="https://www.instagram.com/wagerattack"
        target={"_blank"}
        className={styles["social-button"]}
        rel="noreferrer"
      >
        <FontAwesomeIcon
          icon={faInstagram}
          style={{ color: "#E43F40", fontSize: "22px" }}
        />
      </a>
      <a
        href="https://twitter.com/wagerattack"
        target={"_blank"}
        rel="noreferrer"
        className={styles["social-button"]}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 510 510"
          width={22}
          height={22}
        >
          <path
            fill="#E43F40"
            d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
          />
        </svg>
      </a>
      <a
        href="https://www.tiktok.com/@wagerattack"
        target={"_blank"}
        rel="noreferrer"
        className={styles["social-button"]}
      >
        <FontAwesomeIcon
          icon={faTiktok}
          style={{ color: "#E43F40", fontSize: "22px" }}
        />
      </a>
      <a
        href="https://www.snapchat.com/add/wagerattack"
        target={"_blank"}
        rel="noreferrer"
        className={styles["social-button"]}
      >
        <FontAwesomeIcon
          icon={faSnapchat}
          style={{ color: "#E43F40", fontSize: "22px" }}
        />
      </a>
    </div>
  );
};
