import React from "react";

import styles from "../../styles/videos.module.scss";
import { getVideos, getCountDown } from "../../utils/api";

interface Props {
  data: Video[];
  onLoaded: (data: Video[]) => void;
}

export interface Video {
  title: string;
  description: string;
  link: string;
}

export const VideosComponent: React.FC<Props> = ({ data, onLoaded }) => {
  const getShortTitle = (title: string) => {
    return title.split("-")[0].replace(/'/g, "");
  };

  React.useEffect(() => {
    const getData = async () => {
      const res = await getVideos();
      if (res.INFO?.GET) {
        onLoaded([res.INFO.GET] as Video[]);
      }
    };
    if (!data.length) {
      getData();
    }
  }, [data, onLoaded]);

  return (
    <div className={styles.videos}>
      {/* <div className={styles.card}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h3>Slots, Blackjack, Roulette and More!</h3>
          </div>
          <p className={styles["long-title"]}>
            Slots, Blackjack, Roulette and More!
          </p>
          <p>100+ Games for you to choose from including:</p>
          <ul>
            <li>Slots</li>
            <li>Blackjack</li>
            <li>Roulette</li>
            <li>Baccarat</li>
            <li>Live Dealer Table Games</li>
          </ul>
          <p>More ways for you to win!</p>
        </div>
        <div className={styles.video}>
          <div className={styles["video-container"]}>
            <iframe
              src={`https://www.youtube.com/watch?v=IGjE_zgs2Hw`}
            ></iframe>
          </div>
        </div>
      </div> */}
      {/* <div className={styles.card}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h3>New & Improved PROP BUILDER</h3>
          </div>
          <p className={styles["long-title"]}>New & Improved PROP BUILDER</p>
          <p></p>
        </div>
        <div className={styles.video}>
          <div className={styles["video-container"]}>
            <iframe
              src={`https://www.youtube.com/watch?v=IGjE_zgs2Hw`}
            ></iframe>
          </div>
        </div>
      </div> */}
      {data.map((video, index) => (
        <div className={styles.card} key={index}>
          <div className={styles.content}>
            <div className={styles.title}>
              <h3>{getShortTitle(video.title)}</h3>
            </div>
            <p className={styles["long-title"]}>
              {video.title.replace(/'/g, "")}
            </p>
            <p>{video.description}</p>
          </div>
          <div className={styles.video}>
            <div className={styles["video-container"]}>
              <iframe
                src={`https://www.youtube.com/embed/${video.link}`}
              ></iframe>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
