import React from "react";
import styles from "../../styles/auth-container.module.scss";
import { ButtonComponent } from "../commons/Button.component";
import { SwitchModalType } from "../../interfaces";

interface Props {
  openModal: (modal: SwitchModalType) => void;
}

export const AuthContainerComponent: React.FC<Props> = ({ openModal }) => {
  return (
    <>
      <div className={styles["auth-container"]}>
        <ButtonComponent
          style="secondary"
          onClick={() => {
            openModal("login");
          }}
        >
          Login
        </ButtonComponent>
        <ButtonComponent
          style="primary"
          onClick={() => {
            openModal("register");
          }}
        >
          Register
        </ButtonComponent>
      </div>
    </>
  );
};
