import React from "react";

import { resetPassword } from "../../utils/api";
import { SwitchModalType } from "../../interfaces";

import styles from "../../styles/modal.module.scss";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  closeModal: () => void;
  switchModal: (modal: SwitchModalType) => void;
}

export const ForgotFormComponent: React.FC<Props> = ({
  closeModal,
  switchModal,
}) => {
  const [error, setError] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [success, setSuccess] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const onSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault();
      setError("");
      if (!email) {
        setError("Email is required");
      }
      setSuccess(true);
      setLoading(true);
      const res = await resetPassword(email);
      setLoading(false);
      if (res == -1) {
        setError("Email not found");
      } else {
        setSuccess(true);
      }
      setSuccess(true);
    } catch (error) {
      setError("Something went wrong");
      setSuccess(false);
      setLoading(false);
    }
  };

  const closeModalHandler = () => {
    setSuccess(false);
    setError("");
    setEmail("");
    closeModal();
  };

  return (
    <div className={`${styles.modal} ${styles["modal--small"]}`}>
      <div className={styles.header}>
        <h2>Enter your info</h2>
        <div className={styles.close} onClick={closeModalHandler}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className={styles.form}>
        {loading ? (
          <p>Please wait...</p>
        ) : (
          <p
            className={`${styles.message} ${
              error ? styles.error : styles.success
            }`}
          >
            {error ? error : success ? "Email sent" : ""}
          </p>
        )}
        <form name="client login">
          <div>
            <input
              type="email"
              name="email"
              onChange={onChange}
              placeholder="Type your email"
            />
          </div>
          <button
            className={loading ? styles.disabled : ""}
            onClick={onSubmit}
            type="submit"
            disabled={loading}
          >
            Send email
          </button>
          <button onClick={closeModalHandler} className={styles.cancel}>
            Cancel
          </button>
          <a href="#" onClick={() => switchModal("login")}>
            Login
          </a>
        </form>
      </div>
    </div>
  );
};
