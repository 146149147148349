export const dataMockStepOne = [
  {
    id: 1,
    name: "coinbase",
    img: "/assets/img/wager/coinbase.png",
    items: [
      {
        item: "Quick and easy sign-up process.",
      },
      {
        item: "Beginner-friendly web and mobile app interface.",
      },
      {
        item: "Supports more than 150 cryptocurrencies.",
      },
    ],
  },
  {
    id: 2,
    name: "crypto.com",
    img: "/assets/img/wager/crypto.png",
    items: [
      {
        item: "Quick and easy sign-up process.",
      },
      {
        item: "Beginner-friendly web and mobile app interface.",
      },
      {
        item: "Supports more than 150 cryptocurrencies.",
      },
    ],
  },
  {
    id: 3,
    name: "kraken",
    img: "/assets/img/wager/kraken.png",
    items: [
      {
        item: "Quick and easy sign-up process.",
      },
      {
        item: "Beginner-friendly web and mobile app interface.",
      },
      {
        item: "Supports more than 150 cryptocurrencies.",
      },
    ],
  },
  {
    id: 4,
    name: "gemini",
    img: "/assets/img/wager/gemini.png",
    items: [
      {
        item: "Quick and easy sign-up process.",
      },
      {
        item: "Beginner-friendly web and mobile app interface.",
      },
      {
        item: "Supports more than 150 cryptocurrencies.",
      },
    ],
  },
];

export const dataMockStepTwo = [
  {
    id: 1,
    name: "Bitcoin (BTC)",
    img: "/assets/img/icons/bitcoin.png",
    description:
      "World’s most well-known cryptocurrency and number 1 player’s choice.",
  },
  {
    id: 2,
    name: "Ethereum (ETH)",
    img: "/assets/img/wager/eth.png",
    description:
      "World’s second-largest virtual currency, facilitates operations in the Ethereum network.",
  },
  {
    id: 3,
    name: "Tether (USDT)",
    img: "/assets/img/wager/tether.png",
    description:
      "Type of cryptocurrency known as a stablecoin, aiming to keep a steady valuation.",
  },
  // {
  //   id: 4,
  //   name: "Tron (TRX)",
  //   img: "/assets/img/wager/tron.png",
  //   description:
  //     "Native currency of the Tron blockchain, an open-source platform similar to Ethereum.",
  // },
  {
    id: 5,
    name: "USD Coin (USDC)",
    img: "/assets/img/wager/usd-coin.png",
    description:
      "Ethereum-based stablecoin, backed by fully reserved U.S. dollar assets.",
  },
  // {
  //   id: 6,
  //   name: "Binance Coin (BNB)",
  //   img: "/assets/img/wager/binance.png",
  //   description:
  //     "Cryptocurrency issued by Binance, the world’s largest cryptocurrency exchange.",
  // },
  {
    id: 7,
    name: "Ripple (XRP)",
    img: "/assets/img/wager/ripple.png",
    description:
      "Global payments network where transactions are lightning-fast and cost less.",
  },
  // {
  //   id: 8,
  //   name: "Cardano (ADA)",
  //   img: "/assets/img/wager/cardano.png",
  //   description:
  //     "Primary digital currency of the Cardano platform, alternative to Ethereum.",
  // },
  // {
  //   id: 9,
  //   name: "Solana (SOL)",
  //   img: "/assets/img/wager/solana.png",
  //   description:
  //     "More transactions per second and lower fees than rival blockchains, like Ethereum.",
  // },
  {
    id: 10,
    name: "Dogecoin (DOGE)",
    img: "/assets/img/wager/dogecoin.png",
    description:
      "Peer-to-peer, open source cryptocurrency, based on Litecoin’s scrypt technology.",
  },
  // {
  //   id: 11,
  //   name: "Polygon (MATIC)",
  //   img: "/assets/img/wager/polygon-coin.png",
  //   description:
  //     "Polygon’s native token, enables transactions to be processed quickly and at a low cost.",
  // },
  // {
  //   id: 12,
  //   name: "Avalanche (AVAX)",
  //   img: "/assets/img/wager/avalanche.png",
  //   description:
  //     "Native token for the Avalanche blockchain platform, a competitor to Ethereum.",
  // },
  {
    id: 13,
    name: "Shiba Inu (SHIB)",
    img: "/assets/img/wager/shiba.png",
    description:
      "Ethereum-based altcoin, considered to be an alternative to Dogecoin.",
  },
  {
    id: 14,
    name: "Litecoin (LTC)",
    img: "/assets/img/wager/litecoin.png",
    description:
      "Peer-to-peer cryptocurrency that enables instant, near-zero cost payments worldwide.",
  },
  // {
  //   id: 15,
  //   name: "Bitcoin Cash (BCH)",
  //   img: "/assets/img/wager/bitcoin-cash.png",
  //   description:
  //     "Alternative version of Bitcoin, with shorter transaction times and lower fees.",
  // },
  // {
  //   id: 16,
  //   name: "Stellar (XLM)",
  //   img: "/assets/img/wager/stellar.png",
  //   description:
  //     "Faster, cheaper, and more energy-efficient than typical blockchain-based systems.",
  // },
];

export const dataMockStepThree = [
  {
    id: 1,
    name: " Go to Cashier and select your preferred cryptocurrency",
  },
  {
    id: 2,
    name: "Enter the desired deposit amount",
  },
  {
    id: 3,
    name: "Copy the address provided on the cashier",
  },
  {
    id: 4,
    name: "Go to exchange, click ‘Send’ or ‘Withdraw’, enter amount, paste address and send",
  },
];
