import React from "react";
import "./styles.scss";
import { dataMockStepOne } from "./datas";

const CryptoStepOne = () => {
  return (
    <div className="crypto-content_steps">
      <div className="crypto-step max-container">
        <div className="crypto-step_header">
          <span>1</span>
          <h3>Sign up with a crypto exchange</h3>
        </div>
        <div className="crypto-step_content">
          <p>
            A crypto exchange is a digital marketplace where you can buy, sell
            and send crypto currency.
          </p>
          <p className="crypto-step_content__fill italic">
            Most exchanges, you can transfer funds from your bank account or
            debit/credit card.
          </p>
          <p className="italic">We recommend these exchanges:</p>
        </div>
      </div>
      <div className="crypto-step_cards">
        <ul className="cards-one">
          {dataMockStepOne.map((data) => (
            <li className="list-item" key={data.id}>
              <img src={data.img} alt={data.name} />
              <ul className="list-subitem">
                {data.items.map(({ item }, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CryptoStepOne;
