import React from "react";
import "./styles.scss";
import { dataMockStepThree } from "./datas";

const CryptoStepThree = () => {
  return (
    <div className="crypto-content_steps">
      <div className="crypto-step max-container">
        <div className="crypto-step_header">
          <span>3</span>
          <h3>Send crypto to your Wager Attack account</h3>
        </div>
        <div className="crypto-step_content">
          <p>
            Once the cryptocurrency is sent, the funds will appear in your
            account within minutes.
          </p>
        </div>
      </div>
      <div className="crypto-step_cards">
        <ul className="cards-three">
          {dataMockStepThree.map((data, index) => (
            <>
              <li className="list-item" key={data.id}>
                <div className="dots">{index + 1}</div>
                <h4>{data.name}</h4>
              </li>
              {index < 3 && (
                <img src="/assets/img/icons/arrow-left.svg" alt="arrow-left" />
              )}
            </>
          ))}
        </ul>
      </div>
      <div className="footer-step-three max-container flex flex:column gap:4">
        <p>
          Once the cryptocurrency is sent, the funds will appear in your account
          within minutes.
        </p>
        <p>Remember to <strong>always</strong> use a new crypto address when depositing.</p>
      </div>
    </div>
  );
};

export default CryptoStepThree;
